import Surrounding1 from '../../Media/Greenwood/Surrounding Area/Surrounding (1).webp'
import Surrounding2 from '../../Media/Greenwood/Surrounding Area/Surrounding (2).webp'
import Surrounding3 from '../../Media/Greenwood/Surrounding Area/Surrounding (3).webp'
import Surrounding4 from '../../Media/Greenwood/Surrounding Area/Surrounding (4).webp'
import Surrounding5 from '../../Media/Greenwood/Surrounding Area/Surrounding (5).webp'
import Surrounding6 from '../../Media/Greenwood/Surrounding Area/Surrounding (6).webp'
import Surrounding7 from '../../Media/Greenwood/Surrounding Area/Surrounding (7).webp'
import Surrounding8 from '../../Media/Greenwood/Surrounding Area/Surrounding (8).webp'
import Surrounding9 from '../../Media/Greenwood/Surrounding Area/Surrounding (9).webp'
import Surrounding10 from '../../Media/Greenwood/Surrounding Area/Surrounding (10).webp'




const Surroundingimages =[
    Surrounding1,Surrounding2,Surrounding3,Surrounding4,Surrounding5,Surrounding6,Surrounding7,Surrounding8,Surrounding9,Surrounding10
]

export default Surroundingimages;