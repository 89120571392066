import React from "react";
import "./Produk1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Mahoniimages from "./Mahoni.js";
import Merbauimages from "./Merbau.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsUpDownLeftRight} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Garnetwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282177785143&text=Halo%20Adjie,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20Mahoni%20https://gcb-greenwoodscountrybogor.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Winettawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282177785143&text=Halo%20Adjie,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20Merbau%20https://gcb-greenwoodscountrybogor.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Tuscanywa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281527816144&text=Halo%20Marketing%20Citraland,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20Tuscany%20https://citraland-tallasa.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container-gcb">
      <div id="akasia" className="container-header1">
        {" "}
        Cluster Akasia
      </div>
      <div className="containercard2">
        <div className="card">
          <Slider {...settings}>
            {Mahoniimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Mahoni</div>
              <span>Subsidi DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">3</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color="#393939" icon={faArrowsUpDownLeftRight} />
            <span> : 66m²</span>
            <FontAwesomeIcon color="#393939" icon={faHouse} />
            <span> : 48m²</span>
            <FontAwesomeIcon color="#393939" icon={faBed} />
            <span>: 2</span>
            <FontAwesomeIcon color="#393939" icon={faShower} />
            <span>: 1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Garnetwa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Merbauimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Merbau</div>
              <span>Subsidi DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">4</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color="#393939" icon={faArrowsUpDownLeftRight} />
            <span> : 66m²</span>
            <FontAwesomeIcon color="#393939" icon={faHouse} />
            <span> : 75m²</span>
            <FontAwesomeIcon color="#393939" icon={faBed} />
            <span>: 2</span>
            <FontAwesomeIcon color="#393939" icon={faShower} />
            <span>: 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Winettawa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
