import React from "react";
import "./footer.scss";
import logo from "../../Media/Greenwood/Logo Greenwood.png";
import {Link} from "react-router-dom";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img
            className="gambar-logo"
            src={logo}
            alt="logo-citraland-surabaya"
          />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h1>Marketing Gallery</h1>
            <h2>Greenwoods Country Bogor</h2>
            <h3>
              Jl. Atang Senjaya No.208, Bantarjaya, Kec. Ranca Bungur, Kabupaten
              Bogor, Jawa Barat 16310
            </h3>
            <h4>Contact us : +6282177785143 (Adjie)</h4>
          </div>
          <Link to="/privacy-policy">
            <div className="footer-contact">Privacy Policy</div>
          </Link>
        </div>
      </div>
      <div className="container-bawah">© 2024 All Rights Reserved.</div>
    </div>
  );
};

export default footer;
