import Meranti1 from '../../../Media/Greenwood/Cluster Flamboyan/Tipe Meranti/Meranti (1).webp'
import Meranti2 from '../../../Media/Greenwood/Cluster Flamboyan/Tipe Meranti/Meranti (2).webp'
import Meranti3 from '../../../Media/Greenwood/Cluster Flamboyan/Tipe Meranti/Meranti (3).webp'
import Meranti4 from '../../../Media/Greenwood/Cluster Flamboyan/Tipe Meranti/Meranti (4).webp'
import Meranti5 from '../../../Media/Greenwood/Cluster Flamboyan/Tipe Meranti/Meranti (5).webp'
import Meranti6 from '../../../Media/Greenwood/Cluster Flamboyan/Tipe Meranti/Meranti (6).webp'
import Meranti7 from '../../../Media/Greenwood/Cluster Flamboyan/Tipe Meranti/Meranti (7).png'

const Merantiimages =[
Meranti1, Meranti2, Meranti3, Meranti4, Meranti5, Meranti6, Meranti7 
]

export default Merantiimages;