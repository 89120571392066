import React from "react";
import "./surroundingarea.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import fasilitasimages from "./surroundingarea.js";

const fasilitas = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  return (
    <div className="container-surrounding">
      <div id="surrounding" className="judul-surrounding">
        <h1>Surrounding Area</h1>
      </div>
      <div className="container-card">
        <div className="kartu">
          <Slider {...settings}>
            {fasilitasimages.map((image, index) => (
              <div>
                <img
                  className="kartugambar1"
                  key={index}
                  src={image}
                  alt="athena"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default fasilitas;
