import Merbau1 from '../../../Media/Greenwood/Cluster_Akasia/Merbau/Merbau (1).webp'
import Merbau2 from '../../../Media/Greenwood/Cluster_Akasia/Merbau/Merbau (2).webp'
import Merbau3 from '../../../Media/Greenwood/Cluster_Akasia/Merbau/Merbau (3).webp'
import Merbau4 from '../../../Media/Greenwood/Cluster_Akasia/Merbau/Merbau (4).webp'
import Merbau5 from '../../../Media/Greenwood/Cluster_Akasia/Merbau/Merbau (5).webp'
import Merbau6 from '../../../Media/Greenwood/Cluster_Akasia/Merbau/Merbau (6).webp'


const Merbauimages =[
Merbau1, Merbau2, Merbau3, Merbau4, Merbau5, Merbau6 
]

export default Merbauimages;