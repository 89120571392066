import Mahoni1 from '../../../Media/Greenwood/Cluster_Akasia/Mahoni/Mahoni (1).webp'
import Mahoni2 from '../../../Media/Greenwood/Cluster_Akasia/Mahoni/Mahoni (2).webp'
import Mahoni3 from '../../../Media/Greenwood/Cluster_Akasia/Mahoni/Mahoni (3).webp'
import Mahoni4 from '../../../Media/Greenwood/Cluster_Akasia/Mahoni/Mahoni (4).webp'
import Mahoni5 from '../../../Media/Greenwood/Cluster_Akasia/Mahoni/Mahoni (5).webp'
import Mahoni6 from '../../../Media/Greenwood/Cluster_Akasia/Mahoni/Mahoni (6).webp'
import Mahoni7 from '../../../Media/Greenwood/Cluster_Akasia/Mahoni/Mahoni (7).webp'

const Mahoniimages =[
Mahoni1, Mahoni2, Mahoni3, Mahoni4, Mahoni5, Mahoni6, Mahoni7  
]

export default Mahoniimages;