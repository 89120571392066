import Fasilitas1 from '../../Media/Greenwood/Fasilitas/1.webp'
import Fasilitas2 from '../../Media/Greenwood/Fasilitas/2.webp'
import Fasilitas3 from '../../Media/Greenwood/Fasilitas/3.webp'
import Fasilitas4 from '../../Media/Greenwood/Fasilitas/4.webp'
import Fasilitas5 from '../../Media/Greenwood/Fasilitas/5.webp'





const fasilitasimages =[
    Fasilitas1,Fasilitas2,Fasilitas3,Fasilitas4,Fasilitas5
]

export default fasilitasimages;